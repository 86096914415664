import _ from 'lodash';

export const memuListTransfers = [
    {
        id: 'hash',
        name: 'Txn Hash',
        align: 'left',
        fromNow: false
    },
    {
        id: 'method',
        name: 'Method',
        align: 'left',
        fromNow: false,
    },
    {
        id: 'timestamp',
        name: 'Age',
        fromNow: true,
        align: 'left'
    },
    {
        id: 'timestamp',
        name: 'Date time (UTC)',
        fromNow: false,
        align: 'left'
    },
    {
        id: 'from',
        name: 'From',
        fromNow: false,
        align: 'left'
    },
    {
        id: 'arrow',
        name: '',
        fromNow: false,
        align: 'left'
    },
    {
        id: 'to',
        name: 'To',
        fromNow: false,
        align: 'left'
    },
    {
        id: 'quantity',
        name: 'Quantity',
        fromNow: false,
        align: 'left'
    }
]

export const scrollTop = (top = 0) => {
    document.body.scrollTop = top;
    document.documentElement.scrollTop = top;
};
export const formatNumber = (number) => {
    if (_.isUndefined(number)) return;
    return number.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
};

import { Box, Grid, makeStyles, styled, Typography, Tooltip } from '@material-ui/core';
import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment';

import { useMediaQuery } from 'react-responsive';

const BlockItems = ({ element, index, animation, isView }) => {
    const isIpad = useMediaQuery({ minWidth: 501, maxWidth: 1200 });
    const isMobile = useMediaQuery({ maxWidth: 499 })
    const countUp = (element, index) => {
        let seconds = (moment().utc().valueOf() - moment(_.get(element, 'timestamp')).utc().valueOf()) / 1000;

        let interval = seconds / 31536000;

        if (interval > 1) {
            return Math.floor(interval) + " years ago";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " months ago";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " days ago";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + " hours ago";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " minutes ago";
        }
        return Math.floor(seconds) + " seconds ago";
    }
    const Item = styled(Box)(({ theme }) => ({
        ...theme.typography.body2,
        color: theme.palette.text.secondary,
        backgroundColor: '#fff',
        height: isIpad ? 180 : 160,
        border: '1px solid lightgray',
        borderRadius: 4,
        borderLeft: '4px solid #00837B',
    }));
    const useStyles = makeStyles((theme) => ({
        buttonHead: {
            border: '1px solid #15BBA6',
            color: '#15BBA6',
            fontSize: 12,
            padding: '5px 20px',
            textTransform: 'capitalize',
            '&:hover': {
                color: '#fff',
                backgroundColor: '#15BBA6'
            }
        },
        fontText: {
            fontSize: 13, 
            color: '#00837B'
        },
        boxContainer: {
            border: '1px solid lightgray', 
            overflowY: 'scroll',
            borderRadius: 3,
            borderLeft: '4px solid #00837B',
            marginLeft: 0
        },
        space: {
            width: 10,
            height: 30
        },
        titleBox: {
            fontWeight: 'bold',
            color: '#00837B',
            fontSize: 13,
            margin: 10
        },
        bodyBox: {
            marginLeft: 10,
            marginRight: 10,
            fontSize: 13,
            color: '#969696',
            display: 'flex',
            alignItems: 'center',
            
        },
        boxBlockContainer: {
           
        },
        boxBottom: {
            display: 'flex',
            alignItems: 'center',
            margin: '0px 10px',
            fontSize: 13,
            color: '#969696',
            
        }
    }));


    const classes = useStyles();

    return (
        <Grid key={_.get(element, 'number')} item xs={isIpad ? 6 : isMobile ? 12 : 3} className={clsx(classes.boxBlockContainer, animation)}>
            <Item>
                <Grid container direction="row" alignItems="center" style={{ height: '40%' }}>
                    <Typography className={clsx(classes.titleBox)}>{_.get(element, 'number')}</Typography>
                    
                </Grid>
                <Grid container direction="row" style={{ height: '60%', backgroundColor: '#F6F7F9' }}>
                    <Grid container justifyContent={'space-evenly'} direction={'column'}>
                        <Grid container>
                            <Typography className={clsx(classes.bodyBox)}>
                                {_.get(element, 'transactionCount') || 0} Transactions
                            </Typography>
                            <Typography className={clsx(classes.bodyBox)}>
                                {/* {moment(new Date(_.get(element, 'timestamp'))).fromNow()} */}
                                {countUp(element, index)}
                            </Typography>
                        </Grid>
                        <Tooltip title={_.get(element, 'miner')}>
                            <div className={classes.boxBottom}>
                                Validator {" "} <Typography style={{ color: '#00837B', fontSize: 12, marginLeft: 6 }}>{_.get(element, 'miner')?.substring(0, 7) + " - " +  _.get(element, 'miner')?.substring(34, Number(_.findLastIndex(_.get(element, 'miner')) + 1))}</Typography>
                            </div> 
                        </Tooltip>
                    </Grid>
                </Grid>
            </Item>
        </Grid>
    )
}

export default BlockItems

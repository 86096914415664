import { Box, Grid, styled, Typography, Chip, makeStyles, IconButton } from '@material-ui/core'
import React, { useState, useEffect, Fragment } from 'react';
import clsx from 'clsx';
import { useParams } from 'react-router-dom'
import { ReactComponent as NavigateBeforeIcon } from '../../assets/icons/ArrowBackIos.svg';
import { ReactComponent as NavigateNextIcon } from '../../assets/icons/ArrowForwardIos.svg';
import { transactionAPI } from '../../service/index';
import _ from 'lodash';
import { ReactComponent as ArrowRightAltIcon } from '../../assets/icons/ArrowRightAlt.svg';
import moment from 'moment'
import { Skeleton } from '@material-ui/lab';

import { useMediaQuery } from 'react-responsive';

const Transactions = () => {
    const ItemTransaction = styled(Box)(({ theme }) => ({
        ...theme.typography.body2,
        color: theme.palette.text.secondary,
        backgroundColor: '#fff',
        height: 'inherit',
        width: '100%',
        border: '1px solid lightgray',
        borderRadius: 4,
    }));

    const [pagination, setPagination] = useState({
        skip: 0,
        take: 15
    });

    const [transactionList, setTransactionList] = useState([])

    const [currentPage, setCurrentPage] = useState(1);

    const [animation, setAnimation] = useState("animate__animated animate__fadeInUp")

    const useStyles = makeStyles((theme) => ({
        buttonHead: {
            border: '1px solid #15BBA6',
            color: '#15BBA6',
            fontSize: 12,
            padding: '5px 20px',
            textTransform: 'capitalize',
            '&:hover': {
                color: '#fff',
                backgroundColor: '#15BBA6'
            }
        },
        boxContainer: {
            border: '1px solid lightgray', 
            overflowY: 'scroll',
            borderRadius: 3,
            borderLeft: '4px solid #15BBA6',
            marginLeft: 0
        },
        space: {
            width: 10,
            height: 30
        },
        titleBox: {
            fontWeight: 'bold',
            color: '#15BBA6',
            fontSize: 13,
            margin: 10
        },
        bodyBox: {
            marginLeft: 10,
            marginTop: 10,
            fontSize: 13,
            color: '#a3a9b5'
        },
        menuTransations: {
            backgroundColor: '#d1ecf1', 
            height: 50, 
            borderRadius: 6, 
            borderColor: '#bee5eb',
            padding: '0px 20px'
        },
        boxBodyTransaction: {
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 
            height: '100%',
            flexDirection: 'column',
            
        },
        tags: {
            borderRadius: 6, 
            padding: '0px 6px', 
            height: '25px'
        },
        fontText: {
            fontSize: 13,
            color: '#00837B',
            wordBreak: 'break-all'
        },
    }));

    const isIpad = useMediaQuery({ maxWidth: 768 })

    const classes = useStyles();

    const { status } = useParams();

    let oldData = [];

    const initData = () => {
        setAnimation("");
        if(_.isUndefined(status)) {
            transactionAPI.getList(pagination.skip, pagination.take).then((data) => {
                setTransactionList(data);
                if(_.get(data[0], 'hash') !== _.get(oldData[0], 'hash')) {
                    setAnimation("animate__animated animate__fadeInLeft")
                }
                oldData = data
            });
            return;
        }

    }

    useEffect(() => {
        initData()
        let interval = setInterval(() => initData(), 4000);

        return () => {
            clearInterval(interval);
        }
    }, [pagination]);

    const previous = () => {
        setCurrentPage(currentPage - 1);
        setPagination((prev) => ({
            skip: (currentPage - 1) * 15,
            take: 15
        }))
    }

    const next = () => {
        setCurrentPage(currentPage + 1);
        setPagination((prev) => ({
            skip: (currentPage + 1) * 15,
            take: 15
        }))
    }

    return (
        <Grid container style={{ padding: '24px 24px' }}>
            {/* Body Container */}
            <Box sx={{ flexGrow: 1, backgroundColor: '#fff', padding: 24, borderRadius: 12 }}>
                <Grid direction="column" container>
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item>
                            <Typography style={{ fontSize: 20, fontWeight: 500 }}>Transactions</Typography>
                        </Grid>
                        <Grid item>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <IconButton disabled={currentPage === 1} style={{ display: 'flex', alignItems: 'center' }}>
                                    <NavigateBeforeIcon onClick={previous} style={{ width: 20 }} />
                                </IconButton>
                                <Typography style={{ fontSize: 12 }}>Page {currentPage}</Typography>
                           
                               <IconButton style={{ display: 'flex', alignItems: 'center' }}>
                                    <NavigateNextIcon onClick={next} style={{ width: 20 }} />
                               </IconButton>
                            </div>
                        </Grid>
                    </Grid>
                    {_.isEmpty(transactionList) ? (
                        <Fragment>
                            <Skeleton height={80} />
                            <Skeleton height={80} />
                            <Skeleton height={80} />
                            <Skeleton height={80} />
                            <Skeleton height={80} />
                            <Skeleton height={80} />
                        </Fragment>
                    ) : (
                        <Fragment>
                            {_.map(transactionList, (element, index) => {
                                return (
                                    <Fragment>
                                        <div className={classes.space}></div>
                                        <Grid item xs={12}  className={animation}>
                                            <ItemTransaction>
                                                <Grid container direction="row" style={{ height: 'inherit' }}>
                                                    <Grid item xs={12} md={2} style={{ border: '1px solid #E07E37', borderLeft: '3px solid #E07E37', height: isIpad ? '100px' : 'inherit' }} alignItems="center">
                                                        <Box className={clsx(classes.boxBodyTransaction)}>
                                                            <span style={{ fontWeight: 'bold', color: '#E07E37', fontSize: 13 }}>Transations</span>
                                                            <span style={{ color: '#E07E37', fontSize: 13 }}>{_.get(element, 'status')}</span>
                                                        </Box>
                                                    </Grid>
                                                    {isIpad && (<div style={{ width: 10, height: 10 }}></div>)}
                                                    <Grid item xs={12} md={10} style={{ padding: 10, display: 'flex', alignItems: 'center' }}>
                                                        <Grid container direction="column" justifyContent="center">
                                                            <Grid xs={12} style={{ marginBottom: 10 }}>
                                                                <Typography className={clsx(classes.fontText)}>
                                                                    {_.get(element, 'hash')} {" "}
                                                                </Typography>
                                                                <div style={{ height: 16 }}></div>
                                                            </Grid>
                                                            {isIpad && (<div style={{ width: 10, height: 10 }}></div>)}
                                                            <Grid xs={12} style={{ marginBottom: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <Typography className={clsx(classes.fontText)}>
                                                                    {_.get(element, 'blockHash')} {" "}
                                                                </Typography>
                                                                {!isIpad && (
                                                                    <div style={{ height: 26 }}>
                                                                        <Typography style={{ display: 'block' }} className={clsx(classes.fontText)}>Block #{_.get(element, 'blockNumber')}</Typography>
                                                                        <Typography  style={{ display: 'block', fontSize: 13 }} >
                                                                            {moment(_.get(element, 'timestamp')).fromNow()} {" "}
                                                                        </Typography>
                                                                    </div>
                                                                )}
                                                            </Grid>
                                                            {isIpad && (<div style={{ width: 10, height: 10 }}></div>)}
                                                            <Grid container alignItems="center" direction="row">
                                                                <Typography className={clsx(classes.fontText)}>
                                                                {_.isEmpty(_.get(element, 'events')) ? (
                                                                    <Fragment>
                                                                        {_.get(element, 'from')}
                                                                    </Fragment>
                                                                ) : (
                                                                    <Fragment>
                                                                        {_.get(element, 'events[0].from')}
                                                                    </Fragment>  
                                                                )}
                                                                </Typography>
                                                                <div style={{ width: isIpad ? '100%' : undefined, marginRight: isIpad ? 'auto' : undefined, alignItems: 'center', display: 'flex' }}>
                                                                    <ArrowRightAltIcon />
                                                                </div>
                                                                <Typography className={clsx(classes.fontText)}>
                                                                    {_.isEmpty(_.get(element, 'events')) ? (
                                                                        <Fragment>
                                                                            {_.get(element, 'to')}
                                                                        </Fragment>
                                                                    ) : (
                                                                        <Fragment>
                                                                            {_.get(element, 'events[0].to')}
                                                                        </Fragment>
                                                                        
                                                                    )}
                                                                </Typography>
                                                            </Grid>
                                                            
                                                            {isIpad && (
                                                                <Fragment>
                                                                        {isIpad && (<div style={{ width: 10, height: 10 }}></div>)}
                                                                      <Grid container>
                                                                        <Typography style={{ fontSize: 13 }}>
                                                                            {moment(_.get(element, 'timestamp')).fromNow()} {" "}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid container>
                                                                        <Typography className={clsx(classes.fontText)}>
                                                                            Block #{_.get(element, 'blockNumber')}
                                                                        </Typography>
                                                                    </Grid>
                                                                   
                                                                </Fragment>
                                                            )}
                                                           
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </ItemTransaction>
                                        </Grid>
                                    </Fragment>
                                )
                            })}
                        </Fragment>

                    )}
               
                  
                </Grid>
            </Box>
        </Grid>
              
    )
}

export default Transactions

import axios from 'axios';

class OverViewService {
    getOverView() {
        return axios.get(`${process.env.REACT_APP_API}/getcontracts`).then((response) => response.data)
    }
    getCountAddress() {
        return axios.get(`${process.env.REACT_APP_API}/address/count`).then((response) => response.data);
    }
}

export default OverViewService;
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from './components/Header';
import slugs from './constant/slugs';
import Dashboard from './pages/Dashboard';
import Transactions from './pages/Transactions';
import Blocks from './pages/Blocks';
import 'animate.css'
import TransactionValidated from './pages/Transactions/TransactionValidated';
import Results from './pages/Results';
import DetailTransactions from './pages/Transactions/DetailTransactions';

function App() {
   
    return (
        <Router>
            <Header />
            <Switch>
                <Route path={slugs.dashboard} exact render={() => <Dashboard  />} />
                <Route path={slugs.blockList} exact component={Blocks} />
                <Route path={slugs.transactionsChild} exact component={TransactionValidated} />
                <Route path={slugs.transactionList} exact component={Transactions} />
                <Route path={slugs.results} exact render={() => <Results />} />
                <Route path={slugs.details} exact render={() => <DetailTransactions />} />
            </Switch>
        </Router>
    );
}

export default App;

import { Grid, makeStyles, Tabs, Tab, Box } from '@material-ui/core';
import React, { useState } from 'react';
import BlockContainer from '../components/Blocks/BlockContainer';
import TransactionContainer from '../components/Transactions/TransactionContainer';
import OverviewContainer from '../components/OverviewContainer';
import clsx from 'clsx';

const Dashboard = ({ searchText }) => {

    const [tabIndex, setTabIndex] = useState(0);

    const useStyles = makeStyles((theme) => ({
        buttonHead: {
            border: '1px solid #15BBA6',
            color: '#15BBA6',
            fontSize: 12,
            padding: '5px 20px',
            textTransform: 'capitalize',
            '&:hover': {
                color: '#fff',
                backgroundColor: '#15BBA6'
            }
        },
        fontText: {
            fontSize: 13,
            color: '#00837B'
        },
        boxContainer: {
            border: '1px solid lightgray',
            overflowY: 'scroll',
            borderRadius: 3,
            borderLeft: '4px solid #15BBA6',
            marginLeft: 0,
            width: '100%'
        },
        space: {
            width: 10,
            height: 30
        },
        titleBox: {
            fontWeight: 'bold',
            color: '#15BBA6',
            fontSize: 13,
            margin: 10
        },
        bodyBox: {
            marginLeft: 10,
            marginRight: 10,
            fontSize: 13,
            color: '#a3a9b5',
            display: 'flex',
            alignItems: 'center'
        },
        menuTransations: {
            backgroundColor: '#d1ecf1',
            height: 50,
            borderRadius: 6,
            borderColor: '#bee5eb',
            padding: '0px 20px'
        },
        boxBodyTransaction: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
            backgroundColor: 'rgba(253,126,20,.1)'
        },
        tags: {
            borderRadius: 6,
            padding: '0px 6px',
            height: '25px'
        },
        titleContainer: {
            fontSize: 20,
            fontWeight: 500
        },
        container: {
            flexGrow: 1, 
            backgroundColor: '#fff', 
           
            borderRadius: 12, 
            width: '100%',
        },
        indicator: {
            backgroundColor: '#00837B !important'
        },
        tabs: {
            marginBottom: 32
        },
        titleTab: {
            color: '#748CAD', 
            textTransform: 'capitalize', 
            fontSize: 20,
            fontFamily: 'Montserrat'
        }
    }));


    const classes = useStyles();

    const handleChange = (e, newValue) => {
        setTabIndex(newValue);
    }

    return (
        <Grid container direction="column" style={{ padding: '24px 24px' }}>
            {/* Block Container */}
            <BlockContainer />
            {/* Body Container */}
            {/* Overview Container */}
            <OverviewContainer />
           
            <Box className={clsx(classes.container)}>
                <Tabs
                    value={tabIndex}
                    onChange={handleChange}
                    classes={{
                        indicator: classes.indicator
                    }}
                    className={clsx(classes.tabs)}
                >
                    <Tab label={<span className={clsx(classes.titleTab)}>Transfers</span>} />
                </Tabs>
                {
                    tabIndex === 0 && <TransactionContainer searchText={searchText} />
                }
            </Box>
            
        </Grid>
    )
}

export default Dashboard

import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React, { useEffect, useState, Fragment } from 'react'
import { blockAPI } from '../../service';
import BlockItems from './BlockItems';

import _ from 'lodash';

import { useHistory } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';

import DesktopView from '../Responsive/DesktopView';

import { useMediaQuery } from 'react-responsive';
import TabletView from '../Responsive/TabletView';
import MobileView from '../Responsive/MobileView';

const BlockContainer = () => {

    const [animation, setAnimation] = useState("animate__animated animate__fadeInLeft");

    const isIpad = useMediaQuery({ minWidth: 501, maxWidth: 1200 })

    const useStyles = makeStyles((theme) => ({
        buttonHead: {
            border: '1px solid #00837B',
            color: '#00837B',
            fontSize: 12,
            padding: '5px 20px',
            textTransform: 'capitalize',
            '&:hover': {
                color: '#fff',
                backgroundColor: '#00837B'
            }
        },
        fontText: {
            fontSize: 13,
            color: '#00837B'
        },
        boxContainer: {
            border: '1px solid lightgray',
            overflowY: 'scroll',
            borderRadius: 3,
            borderLeft: '4px solid #00837B',
            marginLeft: 0
        },
        space: {
            width: 10,
            height: 30
        },
        titleBox: {
            fontWeight: 'bold',
            color: '#00837B',
            fontSize: 13,
            margin: 10
        },
        bodyBox: {
            marginLeft: 10,
            marginRight: 10,
            fontSize: 13,
            color: '#00837B',
            display: 'flex',
            alignItems: 'center'
        },
        menuTransations: {
            backgroundColor: '#d1ecf1',
            height: 50,
            borderRadius: 6,
            borderColor: '#00837B',
            padding: '0px 20px'
        },
        boxBodyTransaction: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
            backgroundColor: '#00837B'
        },
        tags: {
            borderRadius: 6,
            padding: '0px 6px',
            height: '25px'
        },
        titleContainer: {
            fontSize: 20,
            fontWeight: 500
        }
    }));

    const [blockList, setBlockList] = useState([]);

    const [loading, setLoading] = useState(false);

    const classes = useStyles();
    
    const history = useHistory();

    let oldData = [];

    const getBlocks = async () => {
    
        setAnimation("");
        setLoading(true);
        let blocks = [];
        let result = await blockAPI.getList();
        if(result.data) {
            for(let i = 0; i < 4; i++) {
                blocks.push(result.data[i])
            }
            if(_.get(blocks[0], 'number') !== _.get(oldData[0], 'number')) {
                setAnimation("animate__animated animate__fadeInLeft")
            }
            oldData = blocks;
            setLoading(false);
        }
        setBlockList(blocks);
    }

    useEffect(() => {
        getBlocks()
        let interval;
        interval = setInterval(() => {
            if(!loading) {
                getBlocks()
            }
        }, 4000);

        return () => {
            clearInterval(interval)
        }
      
    }, []);


    return (
        <>
        <MobileView>
        <Box 
                sx={{ 
                    flexGrow: 1, 
                    boxSizing: 'border-box', 
                    backgroundColor: '#fff', 
                    padding: 24, 
                    borderRadius: 12, 
                    height: 'auto',
                    width: '100%'
            }}>
                <Grid container spacing={2} justifyContent="space-between">
                    <Grid item>
                        <Typography 
                            className={clsx(classes.titleContainer)}
                        >
                            Blocks
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button 
                            onClick={() => history.push("/blocks")} 
                            className={clsx(classes.buttonHead)}
                        >
                            View All Blocks
                        </Button>
                    </Grid>
                </Grid>
                {/* <div className={clsx(classes.space)}></div> */}
                <Grid container spacing={2}>
                    {_.isEmpty(blockList) ? (
                        <Fragment>
                            <Grid item xs={12}>
                                <Skeleton variant="rectangular" width={'100%'} height={160} />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton variant="rectangular" width={'100%'} height={160} />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton variant="rectangular" width={'100%'} height={160} />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton variant="rectangular" width={'100%'} height={160} />
                            </Grid>
                        </Fragment>
                    ) : (
                        <Fragment>
                            {_.map(blockList, (element, index) => {
                                return (
                                    <BlockItems element={element} index={index} key={index} animation={animation} />
                                )
                            })}
                        </Fragment>
                    )}
                
                </Grid>
            </Box>
        </MobileView>
        <TabletView>
            <Box 
                sx={{ 
                    flexGrow: 1, 
                    boxSizing: 'border-box', 
                    backgroundColor: '#fff', 
                    padding: 24, 
                    borderRadius: 12, 
                    height: 'auto',
                    width: '100%'
            }}>
                <Grid container spacing={2} justifyContent="space-between">
                    <Grid item>
                        <Typography 
                            className={clsx(classes.titleContainer)}
                        >
                            Blocks
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button 
                            onClick={() => history.push("/blocks")} 
                            className={clsx(classes.buttonHead)}
                        >
                            View All Blocks
                        </Button>
                    </Grid>
                </Grid>
                {/* <div className={clsx(classes.space)}></div> */}
                <Grid container spacing={2}>
                    {_.isEmpty(blockList) ? (
                        <Fragment>
                            <Grid item xs={6}>
                                <Skeleton variant="rectangular" width={'100%'} height={160} />
                            </Grid>
                            <Grid item xs={6}>
                                <Skeleton variant="rectangular" width={'100%'} height={160} />
                            </Grid>
                            <Grid item xs={6}>
                                <Skeleton variant="rectangular" width={'100%'} height={160} />
                            </Grid>
                            <Grid item xs={6}>
                                <Skeleton variant="rectangular" width={'100%'} height={160} />
                            </Grid>
                        </Fragment>
                    ) : (
                        <Fragment>
                            {_.map(blockList, (element, index) => {
                                return (
                                    <BlockItems element={element} index={index} key={index} animation={animation} />
                                )
                            })}
                        </Fragment>
                    )}
                
                </Grid>
            </Box>
        </TabletView>
        <DesktopView>
            <Box 
                sx={{ 
                    flexGrow: 1, 
                    boxSizing: 'border-box', 
                    backgroundColor: '#fff', 
                    padding: 24, 
                    borderRadius: 12, 
                    height: 'auto',
                    width: '100%'
            }}>
                <Grid container spacing={2} justifyContent="space-between">
                    <Grid item>
                        <Typography 
                            className={clsx(classes.titleContainer)}
                        >
                            Blocks
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button 
                            onClick={() => history.push("/blocks")} 
                            className={clsx(classes.buttonHead)}
                        >
                            View All Blocks
                        </Button>
                    </Grid>
                </Grid>
                {/* <div className={clsx(classes.space)}></div> */}
                <Grid style={{ width: '100%' }} container spacing={2} direction={isIpad ? 'column' : 'row'}>
                    {_.isEmpty(blockList) ? (
                        <Fragment>
                            <Grid item xs={isIpad ? 6 : 3}>
                                <Skeleton variant="rectangular" width={'100%'} height={160} />
                            </Grid>
                            <Grid item xs={isIpad ? 6 : 3}>
                                <Skeleton variant="rectangular" width={'100%'} height={160} />
                            </Grid>
                            <Grid item xs={isIpad ? 6 : 3}>
                                <Skeleton variant="rectangular" width={'100%'} height={160} />
                            </Grid>
                            <Grid item xs={isIpad ? 6 : 3}>
                                <Skeleton variant="rectangular" width={'100%'} height={160} />
                            </Grid>
                        </Fragment>
                    ) : (
                        <Fragment>
                            {_.map(blockList, (element, index) => {
                                return (
                                    <BlockItems  element={element} index={index} key={index} animation={animation} />
                                )
                            })}
                        </Fragment>
                    )}
                
                </Grid>
            </Box>
        </DesktopView>
        </>
        
      
    )
}

export default BlockContainer

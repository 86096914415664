import { Box, Grid, styled, Typography, Chip, makeStyles, IconButton } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import React, { useState, useEffect, Fragment } from 'react';
import clsx from 'clsx';
import { ReactComponent as NavigateBeforeIcon } from '../../assets/icons/ArrowBackIos.svg';
import { ReactComponent as NavigateNextIcon } from '../../assets/icons/ArrowForwardIos.svg';
import { blockAPI } from '../../service';

import moment from 'moment';

import _ from 'lodash'
import { Skeleton } from '@material-ui/lab';

import { useMediaQuery } from 'react-responsive';

const Blocks = () => {

    const [blocks, setBlocks] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [blockPerPage, setBlockPerPage] = useState(15);

    const location = useLocation();

    const isIpad = useMediaQuery({ maxWidth: 768 })

    const maxPage = Math.ceil((_.size(blocks)) / blockPerPage);

    let oldData = [];

    const initData = async () => {
        setAnimation("");
        let result = await blockAPI.getList();
        if(result.data) {
            if(_.get(result.data[0], 'number') !== _.get(oldData[0], 'number')) {
                setAnimation("animate__animated animate__fadeInLeft")
            }
            oldData = result.data;
        }
        setBlocks(result.data);
    }

    const [animation, setAnimation] = useState('animate__animated animate__fadeInUp')

    const ItemTransaction = styled(Box)(({ theme }) => ({
        ...theme.typography.body2,
        color: theme.palette.text.secondary,
        backgroundColor: '#fff',
        height: 'inherit',
        width: '100%',
        border: '1px solid lightgray',
        borderRadius: 4,
    }));

    const indexOfLastBlocks = currentPage * blockPerPage;
    const indexOfFirstBlocks = indexOfLastBlocks - blockPerPage;
    const currentBlocks = blocks.slice(indexOfFirstBlocks, indexOfLastBlocks);

    const useStyles = makeStyles((theme) => ({
        buttonHead: {
            border: '1px solid #15BBA6',
            color: '#15BBA6',
            fontSize: 12,
            padding: '5px 20px',
            textTransform: 'capitalize',
            '&:hover': {
                color: '#fff',
                backgroundColor: '#15BBA6'
            }
        },
        boxContainer: {
            border: '1px solid lightgray', 
            overflowY: 'scroll',
            borderRadius: 3,
            borderLeft: '4px solid #15BBA6',
            marginLeft: 0
        },
        space: {
            width: 10,
            height: 30
        },
        titleBox: {
            fontWeight: 'bold',
            color: '#15BBA6',
            fontSize: 13,
            margin: 10
        },
        bodyBox: {
            marginLeft: 10,
            marginTop: 10,
            fontSize: 13,
            color: '#a3a9b5'
        },
        menuTransations: {
            backgroundColor: '#d1ecf1', 
            height: 50, 
            borderRadius: 6, 
            borderColor: '#bee5eb',
            padding: '0px 20px'
        },
        boxBodyTransaction: {
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 
            height: '100%',
            flexDirection: 'column',
            
        },
        tags: {
            borderRadius: 6, 
            padding: '0px 6px', 
            height: '25px'
        }
    }));



    useEffect(() => {
        initData();
        let interval = setInterval(() => initData(), 4000);
        return () => {
            clearInterval(interval)
        }

    
    }, [])

    const classes = useStyles();

    const next = () => {
        setCurrentPage(currentPage + 1);
    }

    const previous = () => {
        setCurrentPage(currentPage - 1);
    }

    return (
        <Grid container style={{ padding: '24px 24px' }}>
            {/* Body Container */}
            <Box sx={{ flexGrow: 1, backgroundColor: '#fff', padding: 24, borderRadius: 12 }}>
                <Grid direction="column" container>
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item>
                            <Typography style={{ fontSize: 20, fontWeight: 500 }}>Blocks</Typography>
                        </Grid>
                        <Grid item>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <IconButton onClick={previous} disabled={currentPage === 1} style={{ display: 'flex', alignItems: 'center' }}>
                                    <NavigateBeforeIcon fill={currentPage === 1 ? "lightgray" : "black"}  width={"14px"} />
                                </IconButton>
                                <Typography style={{ fontSize: 12 }}>Page {currentPage}</Typography>
                           
                               <IconButton onClick={next} disabled={currentPage === maxPage} style={{ display: 'flex', alignItems: 'center' }}>
                                    <NavigateNextIcon fill={currentPage === maxPage ? "lightgray" : "black"}  width={"14px"} />
                               </IconButton>
                            </div>
                        </Grid>
                    </Grid>
                    <div className={classes.space}></div>
                    {_.isEmpty(currentBlocks) ? (
                        <Fragment>
                            <Skeleton height={80} />
                            <Skeleton height={80} />
                            <Skeleton height={80} />
                            <Skeleton height={80} />
                            <Skeleton height={80} />
                            <Skeleton height={80} />
                        </Fragment>
                    ) : (
                        <Fragment>
                            {_.map(currentBlocks, (element, index) => {
                                return (
                                    <Fragment>
                                        <Grid item xs={12} key={index} className={animation} style={{ margin: '10px 0', borderRadius: 6 }}>
                                            <ItemTransaction>
                                                <Grid container direction="row" style={{ height: 'inherit' }}>
                                                    <Grid item xs={12} md={2} style={{ border: '1px solid #E07E37', borderLeft: '3px solid #E07E37', height: isIpad ? '100px' : 'inherit' }} alignItems="center">
                                                        <Box className={clsx(classes.boxBodyTransaction)}>
                                                            <span style={{ fontWeight: 'bold', color: 'rgb(253, 126, 20)', fontSize: 13 }}>  #{_.get(element, 'number')}</span>
                                                            <span style={{ color: 'rgb(253, 126, 20)', fontSize: 13 }}>Block</span>
                                                        </Box>
                                                    </Grid>
                                                    {isIpad && (<div style={{ width: 10, height: 10 }}></div>)}
                                                    <Grid item xs={10} style={{ padding: 10, display: 'flex', alignItems: 'center' }}>
                                                        <Grid container direction="column" justifyContent="center">
                                                            <Grid xs={12} style={{ marginBottom: isIpad ? undefined : 10, textAlign: 'left', display: 'flex', flexDirection: isIpad ? 'column' : 'row' }}>
                                                                <Typography style={{ fontSize: 13, marginRight: 10 }}>{_.get(element, 'transactionCount') || 0} transactions </Typography>
                                                                <Typography style={{ fontSize: 13 }}>{moment(_.get(element, 'timestamp')).fromNow()}</Typography>
                                                            </Grid>
                                                            <Grid xs={12} style={{ marginBottom: 10 }}>
                                                                <Typography style={{ fontSize: 13 }}>
                                                                    Validator {_.get(element, 'miner')?.substring(0,10)} {" "}
                                                                </Typography>
                                                            </Grid>
                                                
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </ItemTransaction>
                                        </Grid>
                                    </Fragment>
                                    
                                )
                            })}
                        </Fragment>
                    )}
                    
                </Grid>
            </Box>
        </Grid>
              
    )
}

export default Blocks

import _ from "lodash";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Select, Button, MenuItem, FormControl } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import style from "./style.module.scss";
import { scrollTop } from "../helpers";
import { ReactComponent as ArrowBackIos } from '../assets/icons/ArrowBackIos.svg';
import { ReactComponent as ArrowForwardIos } from '../assets/icons/ArrowForwardIos.svg';
import MobileView from './Responsive/MobileView';
import { useMediaQuery } from "react-responsive";

const DesktopTabletView = ({ children }) => {
  const isDesktopTablet = useMediaQuery({ minWidth: 500 });

  return isDesktopTablet && children;
}



const PaginationComponent = ({
  total,
  pageSize,
  pageIndex,
  pageSizeOptions,
  setPageSize,
  setPageIndex,
  pagename
}) => {

  // const scrollTop = () => {
  //     document.body.scrollTop = 100;
  //     document.documentElement.scrollTop = 100;
  // }
  const history = useHistory();
  const handleChangePageSize = (e) => {
    setPageSize(e.target.value);
    setPageIndex(1);
    localStorage.setItem('paging', JSON.stringify({ pagename: pagename, pageIndex: pageIndex + 1, pageSize: pageSize }));
  };

  const handleClickNext = () => {
    setPageIndex(pageIndex + 1);
    localStorage.setItem('paging', JSON.stringify({ pagename: pagename, pageIndex: pageIndex + 1, pageSize: pageSize }));
    //scrollTop(200);
  };

  const handleClickPrev = () => {
    setPageIndex(pageIndex - 1);
    window.localStorage.setItem('paging', JSON.stringify({ pagename: pagename, pageIndex: pageIndex - 1, pageSize: pageSize }));
    //scrollTop(200);
  };

  useEffect(() => {
    if (_.get(JSON.parse(localStorage.getItem('paging')), 'pagename') !== pagename) {
      window.localStorage.removeItem('paging');
    }
  }, []);

  return (
    <>
      <MobileView>
        <div className={style.stylePaginationMobile}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '150px' }}>
            <p>Hiển thị</p>
            <FormControl size="small" style={{ display: 'flex' }}>
              <Select
                className={style.styleSelect}
                defaultValue={pageSize}
                variant="outlined"
                onChange={handleChangePageSize}
              >
                {_.map(pageSizeOptions, (item, key) => (
                  <MenuItem key={key} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className={style.stylePaginationBottom}>
            <div>
              {`${pageSize * (pageIndex - 1) + 1}  \u00A0\u00A0  - \u00A0\u00A0${total < pageIndex * pageSize ? total : pageIndex * pageSize} \u00A0\u00A0of\u00A0\u00A0 ${total}`}
            </div>
            <Button
              className={style.styleButton}
              disabled={pageIndex === 1}
              onClick={handleClickPrev}
            >
              <ArrowBackIos width={"20px"} fontSize="16px" fill={pageIndex === 1 ? "lightgray" : "black"} />
            </Button>
            <Button
              className={style.styleButton}
              disabled={total <= pageIndex * pageSize}
              onClick={handleClickNext}
            >
              <ArrowForwardIos width={"20px"} fill={total <= pageIndex * pageSize ? "lightgray" : "black"} />
            </Button>
          </div>


        </div>
      </MobileView>
      <DesktopTabletView>
        <div className={style.stylePagination}>
          <div>Hiển thị</div>
          <div>
            <FormControl size="small">
              <Select
                className={style.styleSelect}
                defaultValue={pageSize}
                variant="outlined"
                onChange={handleChangePageSize}
              >
                {_.map(pageSizeOptions, (item, key) => (
                  <MenuItem key={key} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          </div>
          <div>{`${pageSize * (pageIndex - 1) + 1} - ${total < pageIndex * pageSize ? total : pageIndex * pageSize
            } of ${total}`}</div>
          <Button
            className={style.styleButton}
            disabled={pageIndex === 1}
            onClick={handleClickPrev}
          >
            <ArrowBackIos width={"20px"} fontSize="16px" fill={pageIndex === 1 ? "lightgray" : "black"} />
          </Button>
          <Button
            className={style.styleButton}
            disabled={total <= pageIndex * pageSize}
            onClick={handleClickNext}
          >
            <ArrowForwardIos width={"20px"} fill={total <= pageIndex * pageSize ? "lightgray" : "black"} />
          </Button>
        </div>
      </DesktopTabletView>
    </>

  );
};

PaginationComponent.propTypes = {
  pageSize: PropTypes.number,
  pageIndex: PropTypes.number,
  pageSizeOptions: PropTypes.array,
  setPageSize: PropTypes.func,
  setPageIndex: PropTypes.func,
};

PaginationComponent.defaultProps = {
  pageSize: 20,
  pageIndex: 1,
  pageSizeOptions: [20, 40, 80, 120],
};

export default PaginationComponent;

import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import OverViewTransaction from '../../components/Transactions/Overview';
import { Grid, Box, Tab, Tabs, CircularProgress } from '@material-ui/core';
import { memuListTransfers } from '../../helpers/index';
import PaginationComponent from '../../components/PaginationComponent';
import TableComponent from '../../components/TableComponent';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { transactionAPI } from '../../service';
import _ from 'lodash'
import { PAGE_INDEX, PAGE_SIZE } from '../../constant/slugs';

const DetailTransactions = () => {
    const { hash } = useParams();
    const [tabIndex, setTabIndex] = useState(0);
    const [balance, setBalance] = useState(0);
    const pagingLocalStorage = JSON.parse(localStorage.getItem('paging'));
    const [pageIndex, setPageIndex] = useState(PAGE_INDEX);
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const [skip, setSkip] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [hashItem, setHashItem] = useState('');

    const [data, setData] = useState();
    const firstRender = useRef(true);
    const useStyles = makeStyles((theme) => ({
        container: {
            flexGrow: 1, 
            backgroundColor: '#fff', 
            borderRadius: 12, 
            width: '100%'
        }, 
        indicator: {
            backgroundColor: '#00837B !important'
        },
        tabs: {
            marginBottom: 32
        },
        titleTab: {
            color: '#748CAD', 
            textTransform: 'capitalize', 
            fontSize: 20,
            fontFamily: 'Montserrat'
        },
        wrapperLoading: {
            width: '100%', 
            justifyContent: 'center', 
            display: 'flex', 
            margin: '24px 0'
        }
    }));

    const classes = useStyles();

    const handleChange = (e, newValue) => {
        setTabIndex(newValue);
    }

    const loadData = async (setLoading) => {
        setLoading && setLoading(true);
        let skipNum = hash !== hashItem ? 0 : skip;
        let pageSizeNum = hash !== hashItem ? PAGE_SIZE : pageSize;
        if(hash !== hashItem) {
            setPageIndex(PAGE_INDEX);
            setPageSize(PAGE_SIZE);
            setSkip(0);
        }
        Promise.all([transactionAPI.getBalanceAddress(hash), transactionAPI.getList(skipNum, pageSizeNum, undefined, hash)]).then(data => {
            if(_.isArray(data) && data[0] && data[1]) {
                setData(data[1]);
                setBalance(data[0]);
            }
            setLoading && setLoading(false)
        })
        setHashItem(hash)
    }

    useEffect(() => {
        let interval = setInterval(() => loadData(), 4000);
        loadData(setLoadingTable);
        return () => {
            clearInterval(interval);
        }
    }, [skip, pageSize, hash]);

    if(loading) return <div className={classes.wrapperLoading}><CircularProgress style={{ margin: 'auto' }} disableShrink /></div>

    return (
        <Grid container direction="column" style={{ padding: '24px 24px' }}>
            <OverViewTransaction address={hash} balance={balance} data={data} />
            <Box className={clsx(classes.container)}>
                <Tabs
                    value={tabIndex}
                    onChange={handleChange}
                    classes={{
                        indicator: classes.indicator
                    }}
                    className={clsx(classes.tabs)}
                >
                    <Tab label={<span className={clsx(classes.titleTab)}>Transfers</span>} />
                </Tabs>
                {
                    tabIndex === 0 && (
                        <Fragment>
                            {loadingTable ? (
                                <div className={clsx(classes.wrapperLoading)}><CircularProgress style={{ margin: 'auto' }} disableShrink /></div>
                            ) : (
                                <>
                                    <TableComponent loading={loadingTable} hash={hash} data={_.get(data, 'transactions')} headMenu={memuListTransfers} />
                                    <PaginationComponent 
                                        setPageSize={setPageSize}
                                        setPageIndex={(index) => {
                                            setPageIndex(index);
                                            setSkip(index * pageSize - pageSize)
                                        }}
                                        pageSizeOptions={[10, 20, 40, 80, 120]}
                                        pageSize={pageSize}
                                        pageIndex={pageIndex}
                                        total={_.get(data, 'totalCount')}  
                                        pagename="transactions"
                                    />
                                </>
                               
                            )}
                     
                        </Fragment>
                    )
                }   
                
            </Box>
        </Grid>
    )
}

export default DetailTransactions

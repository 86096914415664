import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { overViewAPI } from '../service';
import _ from 'lodash';
import { formatNumber } from '../helpers';
import DesktopView from './Responsive/DesktopView';
import TabletView from './Responsive/TabletView';
import { useMediaQuery } from 'react-responsive';

const MobileTabletView = ({ children }) => {
    const isView = useMediaQuery({ maxWidth: 1200 });

    return isView && children;
}

const OverviewContainer = () => {

    const useStyles = makeStyles((theme) => ({
        OverviewContainer: {
            height: '114px',
            overflow: 'hidden',
            '& > .MuiGrid-grid-xs-4:last-child': {
                borderRight: 'none'
            },
            '& > .MuiGrid-grid-xs-12:last-child': {
                borderBottom: 'none'
            }
        },
        container: {
            backgroundColor: '#fff', 
            borderRadius: 12,
            marginBottom: 24,
            marginTop: 24,
            height: '100%',
            width: '100%',
            fontFamily: 'Montserrat'
        },
        title: {
            color: '#748CAD',
            fontSize: 20,
            fontWeight: 500,
            fontFamily: 'Montserrat',
        },
        boxOverview: {
            borderRight: '2px solid #BFBFBF', 
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '0px 24px'
        },
        boxOverViewTablet: { 
            borderBottom: '1px solid #BFBFBF', 
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '12px 24px'
        },
        lastBoxOverViewTablet: {
            borderBottom: 'none'
        },
        titleOverView: {
            color: 'rgba(10, 34, 64, 0.6)',
            fontSize: 16,
            fontWeight: 'bold',
            margin: '2px 0px',
            fontFamily: 'Montserrat'
        },
        overviewResult: {
            fontSize: 24,
            color: 'rgba(10, 34, 64, 0.6)',
            fontFamily: 'Montserrat'
        },
        textUnit: {
            fontSize: 16,
            fontFamily: 'Montserrat'
        }
    }));

    const classes = useStyles();
    const [data, setData] = useState([]);
    const [countAddress, setCountAddress] = useState(0)

    const loadData = () => {
        overViewAPI.getOverView().then(data => {
            setData(data);
        });
        overViewAPI.getCountAddress().then(count => setCountAddress(count));
    }

    useEffect(() => {
        loadData();
        let interval = setInterval(() => loadData(), 4000);
        return () => {
            clearInterval(interval)
        }
    }, [])

    return (
        <>
            <MobileTabletView>
                <Box className={clsx(classes.container)}>
                    <Grid container direction="row" style={{ borderBottom: '1px solid #d8d6d6', padding: '12px 24px' }}>
                        <Typography className={clsx(classes.title)}>Overview</Typography>
                    </Grid>
                    <Grid container direction="column">
                        <Grid item xs={12} className={clsx(classes.boxOverViewTablet)}>
                            <Typography className={clsx(classes.titleOverView)}>TOTAL SUPPLY</Typography>
                            <Typography className={clsx(classes.overviewResult)}>{formatNumber(_.get(data, 'erc20TotalSupply', 0))} <span className={clsx(classes.textUnit)}>{_.get(data, 'tokenSymbol')}</span></Typography>
                        </Grid>
                        <Grid item xs={12} className={clsx(classes.boxOverViewTablet)}>
                            <Typography className={clsx(classes.titleOverView)}>HOLDERS</Typography>
                            <Typography className={clsx(classes.overviewResult)}>{countAddress} <span className={clsx(classes.textUnit)}> Addresses</span></Typography>
                        </Grid>
                        <Grid item xs={12} className={clsx(classes.boxOverViewTablet, classes.lastBoxOverViewTablet)}>
                            <Typography className={clsx(classes.titleOverView)}>TRANSFERS</Typography>
                            <Typography className={clsx(classes.overviewResult)}>{formatNumber(_.get(data, 'transactionCount', 0))} <span className={clsx(classes.textUnit)}>Transactions</span></Typography>
                        </Grid>
                    </Grid>
                </Box>
            </MobileTabletView>
            <DesktopView>
                <Box className={clsx(classes.container)}>
                    <Grid container direction="row" style={{ borderBottom: '2px solid #d8d6d6', padding: '12px 24px' }}>
                        <Typography className={clsx(classes.title)}>Overview</Typography>
                    </Grid>
                    <Grid container direction="row" className={classes.OverviewContainer}>
                        <Grid item xs={4} className={clsx(classes.boxOverview)}>
                            <Typography className={clsx(classes.titleOverView)}>TOTAL SUPPLY</Typography>
                            <Typography className={clsx(classes.overviewResult)}>{formatNumber(_.get(data, 'erc20TotalSupply', 0))} <span className={clsx(classes.textUnit)}>{_.get(data, 'tokenSymbol')}</span></Typography>
                        </Grid>
                        <Grid item xs={4} className={clsx(classes.boxOverview)}>
                            <Typography className={clsx(classes.titleOverView)}>HOLDERS</Typography>
                            <Typography className={clsx(classes.overviewResult)}>{countAddress} <span className={clsx(classes.textUnit)}> Addresses</span></Typography>
                        </Grid>
                        <Grid item xs={4} className={clsx(classes.boxOverview)}>
                            <Typography className={clsx(classes.titleOverView)}>TRANSFERS</Typography>
                            <Typography className={clsx(classes.overviewResult)}>{formatNumber(_.get(data, 'transactionCount', 0))} <span className={clsx(classes.textUnit)}>Transactions</span></Typography>
                        </Grid>
                    </Grid>
                </Box>
            </DesktopView>
        </>
        
       
    )
}

export default OverviewContainer

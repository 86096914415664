import React, { Children, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { formatNumber } from '../../helpers';
import _ from 'lodash'
import { useMediaQuery } from 'react-responsive';
import DesktopView from '../Responsive/DesktopView';
import TabletView from '../Responsive/TabletView';

const MobileTabletView = ({ children }) => {
    const isView = useMediaQuery({ maxWidth: 1200 });

    return isView && children;
}


const OverView = ({ balance, address, hash, data }) => {

    const useStyles = makeStyles((theme) => ({
        OverviewContainer: {
            height: '114px',
            overflow: 'hidden',
             '& > .MuiGrid-item:last-child': {
                borderRight: 'none !important'
            }
        },
        container: {
            backgroundColor: '#fff', 
            borderRadius: 12,
            marginBottom: 24,
            height: '100%',
            width: '100%'
        },
        title: {
            color: '#748CAD',
            fontSize: 24,
            fontWeight: 'bold',
            fontFamily: 'Montserrat'
        },
        boxOverview: {
            borderRight: '2px solid #BFBFBF', 
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '0px 24px'
        },
        boxOverviewTablet: {
            borderBottom: '1px solid #BFBFBF', 
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '12px 24px'
        },
        removeBottomBorder: {
            borderBottom: 'none'
        },
        titleOverView: {
            color: 'rgba(10, 34, 64, 0.6)',
            fontSize: 16,
            fontWeight: 'bold',
            margin: '2px 0px',
            fontFamily: 'Montserrat'
        },
        overviewResult: {
            fontSize: 24,
            color: 'rgba(10, 34, 64, 0.6)',
            fontFamily: 'Montserrat'
        },
        textUnit: {
            fontSize: 16,
            fontFamily: 'Montserrat'
        },
        name: {
            color: '#748CAD',
            fontSize: 14,
            fontFamily: 'Montserrat',
            wordBreak: 'break-word',
            cursor: 'pointer'
        }
    }));

    const classes = useStyles();
    return (
        <>
            <MobileTabletView>
                <Box className={clsx(classes.container)}>
                    <Grid container direction="column" style={{ borderBottom: '1px solid #d8d6d6', padding: '12px 24px', width: '100%' }}>
                        <Typography className={clsx(classes.title)}>Address Details</Typography>
                        <Typography className={clsx(classes.name)}><div>{address}</div></Typography>
                    </Grid>
                    <Grid container direction="column">
                        <Grid item xs={12} className={clsx(classes.boxOverviewTablet)}>
                            <Typography className={clsx(classes.titleOverView)}>TOTAL Ecoins</Typography>
                            <Typography className={clsx(classes.overviewResult)}>{formatNumber(_.get(balance, 'balance', 0))} <span className={clsx(classes.textUnit)}>ECoins</span></Typography>
                        </Grid>
                        <Grid item xs={12} className={clsx(classes.boxOverviewTablet, classes.removeBottomBorder)}>
                            <Typography className={clsx(classes.titleOverView)}>TOTAL TRANSACTIONS</Typography>
                            <Typography className={clsx(classes.overviewResult)}>{_.get(data, 'totalCount', 0)} <span className={clsx(classes.textUnit)}> Transacitons</span></Typography>
                        </Grid>
                    </Grid>
                </Box>
            </MobileTabletView>
            <DesktopView>
                <Box className={clsx(classes.container)}>
                    <Grid container direction="column" style={{ borderBottom: '2px solid #d8d6d6', padding: '12px 24px' }}>
                        <Typography className={clsx(classes.title)}>Address Details</Typography>
                        <Typography className={clsx(classes.name)}>{address}</Typography>
                    </Grid>
                    <Grid container direction="row" className={classes.OverviewContainer}>
                        <Grid item xs={6} className={clsx(classes.boxOverview)}>
                            <Typography className={clsx(classes.titleOverView)}>TOTAL Ecoins</Typography>
                            <Typography className={clsx(classes.overviewResult)}>{formatNumber(_.get(balance, 'balance', 0))} <span className={clsx(classes.textUnit)}>Coins</span></Typography>
                        </Grid>
                        <Grid item xs={6} className={clsx(classes.boxOverview)}>
                            <Typography className={clsx(classes.titleOverView)}>TOTAL TRANSACTIONS</Typography>
                            <Typography className={clsx(classes.overviewResult)}>{_.get(data, 'totalCount', 0)} <span className={clsx(classes.textUnit)}> Transacitons</span></Typography>
                        </Grid>
                    </Grid>
                </Box>
            </DesktopView>
            
        </>
        
    )
}

export default OverView

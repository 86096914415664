const slugs = {
    dashboard: '/',
    blockList: '/blocks',
    transactionList: '/transactions',
    transactionsChild: '/transactions/:status',
    results: '/results',
    details: '/details/:hash'
}

export const PAGE_SIZE = 10;
export const PAGE_INDEX = 1;

export default slugs;
import React from "react";
import {
  TableContainer,
  Table,
  TableCell as MuiTableCell,
  Grid,
  TableHead,
  TableBody,
  TableRow,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/styles";
import _ from "lodash";
import clsx from "clsx";
import moment from "moment";
import { ReactComponent as Arrow } from "../assets/icons/arrow.svg";
import { formatNumber } from "../helpers";

import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const TableComponent = ({ headMenu, data, icon, hash, loading }) => {
  const isIpad = useMediaQuery({ minWidth: 375, maxWidth: 1200 });
  const TableCell = withStyles({
    root: {
      borderBottom: "2px solid #d3d1d1",
    },
  })(MuiTableCell);
  const useStyles = makeStyles({
    table: {
      minWidth: "100%",
      overflowX: "scroll",
      width: 1100,
    },
    tableContainer: {},
    tableCell: {
      fontFamily: "Montserrat",
      color: "#748CAD",
      fontSize: 20,
      fontWeight: 200,
    },
    text: {
      fontFamily: "Montserrat !important",
    },
    textHash: {
      width: "160px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflowX: "hidden",
      fontFamily: "Montserrat",
      "& a": {
        textDecoration: "none",
        color: "#2C7BE5",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    out: {
      backgroundColor: "#FDDED5",
      color: "#E07E37",
      borderRadius: 4,
      width: 66,
      height: 24,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    in: {
      backgroundColor: "#E2F9FA",
      color: "#1CBFC6",
      borderRadius: 4,
      width: 66,
      height: 24,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tag: {
      width: 176,
      height: 24,
      backgroundColor: "#E9F2FF",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 4,
      borderRadius: 4,
      fontFamily: "Montserrat",
    },
    backgroundOut: {
      backgroundColor: "#FDDED5",
    },
    backgroundIn: {
      backgroundColor: "#E2F9FA",
    },
  });
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {_.map(headMenu, (element, index) => (
              <TableCell
                className={clsx(classes.tableCell)}
                key={index}
                align={element.align}
              >
                <Typography className={classes.text}>
                  {_.get(element, "name", null)}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {_.size(data) === 0 ? (
            <TableRow>
              <TableCell align="center" colSpan={8}>
                Data not found
              </TableCell>
            </TableRow>
          ) : (
            <>
              {_.map(data, (row, index) => {
                const from = _.get(row, "events[0].from", "-");
                const to = _.get(row, "events[0].to", "-");
                return (
                  <TableRow key={index}>
                    <TableCell width={180}>
                      <Tooltip title={`${_.get(row, "_id", "-")}`}>
                        <Typography className={classes.textHash}>
                          {_.get(row, "_id", "-")}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell width={180}>
                      <div
                        className={clsx(
                          classes.tag,
                          isIpad &&
                            hash &&
                            _.toLower(from) === _.toLower(hash) &&
                            classes.backgroundOut
                        )}
                      >
                        {_.get(row, "method", "-")}
                      </div>
                    </TableCell>
                    <TableCell width={"200px"}>
                      <Typography className={classes.text}>
                        {moment(new Date(_.get(row, "timestamp"))).fromNow()}
                      </Typography>
                    </TableCell>
                    <TableCell width={180}>
                      <Typography className={classes.text}>
                        {moment(new Date(_.get(row, "timestamp"))).format(
                          "YYYY/MM/DD HH:mm:ss"
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell width={180}>
                      <Tooltip title={`${from}`}>
                        <Typography className={classes.textHash}>
                          {_.toLower(from) !== _.toLower(hash) ? (
                            <Link to={`/details/${from}`}>{from}</Link>
                          ) : (
                            from
                          )}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell width={120} align="center">
                      {_.isEmpty(hash) ? (
                        <Arrow />
                      ) : (
                        <>
                          {loading ? (
                            ""
                          ) : (
                            <>
                              {_.toLower(from) === _.toLower(hash) ? (
                                <div className={classes.out}>OUT</div>
                              ) : (
                                <div className={classes.in}>IN</div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </TableCell>
                    <TableCell width={180}>
                      <Tooltip title={`${to}`}>
                        <Typography className={classes.textHash}>
                          {_.toLower(to) !== _.toLower(hash) ? (
                            <Link to={`/details/${to}`}>{to}</Link>
                          ) : (
                            <Tooltip title={`${to}`}>
                              <Typography className={classes.textHash}>
                                {to}
                              </Typography>
                            </Tooltip>
                          )}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell width={100}>
                      {_.isArray(_.get(row, "events")) && (
                        <Typography className={classes.text}>
                          {formatNumber(
                            _.reduce(
                              _.get(row, "events"),
                              (initialValue, currentValue) => {
                                return (
                                  initialValue + Number(currentValue.value)
                                );
                              },
                              0
                            )
                          )}
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableComponent;

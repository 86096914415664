import React from 'react';
import { useMediaQuery } from 'react-responsive';

const MobileView = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 500 });
    return (
        isMobile && children
    )
}

export default MobileView

import axios from 'axios';
import _ from 'lodash'

class TransactionService {
    getList(skip = 0, take = 15, status = undefined, address = undefined) {
        let queryAddress = !_.isUndefined(address) ? `&address=${address}` : "";
        let queryStatus = !_.isUndefined(status) ? `&status=${status}` : "";
        return axios.get(`${process.env.REACT_APP_API}/transfers?take=${take}&skip=${skip}${queryAddress}${queryStatus}`).then(response => response.data);
    }
    searchData(skip = 0, take = 15, address = undefined) {
        let queryAddress = !_.isUndefined(address) ? `&address=${address}` : "";
        return axios.get(`${process.env.REACT_APP_API}/transfers?take=${take}&skip=${skip}${queryAddress}`).then(response => response.data);
    }
    getBalanceAddress(address) {
        let queryAddress = !_.isUndefined(address) ? `&address=${address}` : "";
        return axios.get(`${process.env.REACT_APP_API}/balanceOf?${queryAddress}`).then(response => {
            return response.data
        })
    }
}
export default TransactionService;
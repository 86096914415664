import { Box, Grid, IconButton, makeStyles, styled, Typography } from '@material-ui/core';
import React, { useEffect, useState, Fragment } from 'react';
import { Skeleton } from '@material-ui/lab';
import _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment';

import { ReactComponent as NavigateBeforeIcon } from '../../assets/icons/ArrowBackIos.svg';
import { ReactComponent as NavigateNextIcon } from '../../assets/icons/ArrowForwardIos.svg';
import { transactionAPI } from '../../service';
import { useParams } from 'react-router';

import { ReactComponent as ArrowRightAltIcon } from '../../assets/icons/ArrowRightAlt.svg';

import { useMediaQuery } from 'react-responsive';

const TransactionValidated = () => {
    const ItemTransaction = styled(Box)(({ theme }) => ({
        ...theme.typography.body2,
        color: theme.palette.text.secondary,
        backgroundColor: '#fff',
        height: 'inherit',
       
        border: '1px solid lightgray',
        borderRadius: 4,
    }));
    const useStyles = makeStyles((theme) => ({
        buttonHead: {
            border: '1px solid #15BBA6',
            color: '#15BBA6',
            fontSize: 12,
            padding: '5px 20px',
            textTransform: 'capitalize',
            '&:hover': {
                color: '#fff',
                backgroundColor: '#15BBA6'
            }
        },
        boxContainer: {
            border: '1px solid lightgray', 
            overflowY: 'scroll',
            borderRadius: 3,
            borderLeft: '4px solid #15BBA6',
            marginLeft: 0
        },
        space: {
            width: 10,
            height: 30
        },
        titleBox: {
            fontWeight: 'bold',
            color: '#15BBA6',
            fontSize: 13,
            margin: 10
        },
        bodyBox: {
            marginLeft: 10,
            marginTop: 10,
            fontSize: 13,
            color: '#a3a9b5'
        },
        menuTransations: {
            backgroundColor: '#d1ecf1', 
            height: 50, 
            borderRadius: 6, 
            borderColor: '#bee5eb',
            padding: '0px 20px'
        },
        boxBodyTransaction: {
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 
            height: '100%',
            flexDirection: 'column'
        },
        tags: {
            borderRadius: 6, 
            padding: '0px 6px', 
            height: '25px'
        },
        fontText: {
            fontSize: 13,
            color: '#00837B',
            wordBreak: 'break-all'
        },
    }));

    const isIpad = useMediaQuery({ maxWidth: 768 })

    const classes = useStyles();

    const [currentPage, setCurrentPage] = useState(1);

    const [transactionList, setTransactionList] = useState([]);

    const [animation, setAnimation] = useState("animate__animated animate__fadeInUp");

    const [loading, setLoading] = useState(false);

    const [pagination, setPagination] = useState({
        skip: 0,
        take: 15
    });

    const { status } = useParams();

    const [test, setTest] = useState(false);


    const previous = () => {
        setCurrentPage(currentPage - 1);
        setPagination((prev) => ({
            skip: (currentPage - 1) * 15,
            take: 15
        }))
    }

    const next = () => {
        setCurrentPage(currentPage + 1);
        setPagination((prev) => ({
            skip: (currentPage + 1) * 15,
            take: 15
        }))
    }

    let oldData = [];

    const initData = async () => {
        setAnimation("");
        const data = await transactionAPI.getList(pagination.skip, pagination.take, _.upperCase(status));
        // .then((data) => {
        //     if(_.get(data[0], 'number') !== _.get(oldData[0], 'number')) {
        //         setAnimation("animate__animated animate__fadeInUp")
        //     }
        //     oldData = data;
        //     setTransactionList(_.get(data, 'transactions'));
        // });
        if(_.get(data[0], 'number') !== _.get(oldData[0], 'number')) {
            setAnimation("animate__animated animate__fadeInUp")
        }
        oldData = data;
        setTransactionList(_.get(data, 'transactions'));
    }

    useEffect(() => {
        async function loadData() {
            setLoading(true);
            await initData();
            setLoading(false);
        }
        loadData();
        let interval = setInterval(() => initData(), 4000);
        return () => {
            clearInterval(interval);
        }
    }, [pagination, status]);

    return (
        <Grid container style={{ padding: '24px 24px' }}>
            <Box sx={{ flexGrow: 1, backgroundColor: '#fff', padding: 24, borderRadius: 12 }}>
                <Grid direction="column" container>
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item>
                            <Typography style={{ fontSize: 20, fontWeight: 500 }}>Transactions</Typography>
                        </Grid>
                        <Grid item>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <IconButton disabled={currentPage === 1} style={{ display: 'flex', alignItems: 'center' }}>
                                    <NavigateBeforeIcon onClick={previous} style={{ width: 20 }} />
                                </IconButton>
                                <Typography style={{ fontSize: 12 }}>Page {currentPage}</Typography>
                           
                               <IconButton disabled={_.isEmpty(transactionList[transactionList.length])} style={{ display: 'flex', alignItems: 'center' }}>
                                    <NavigateNextIcon onClick={next} style={{ width: 20 }} />
                               </IconButton>
                            </div>
                        </Grid>
                    </Grid>
                    {loading ? (
                        <Fragment>
                            <Skeleton height={80} />
                            <Skeleton height={80} />
                            <Skeleton height={80} />
                            <Skeleton height={80} />
                            <Skeleton height={80} />
                            <Skeleton height={80} />
                        </Fragment>
                    ) : (
                        <Fragment>
                            {_.map(transactionList, (element, index) => {
                                return (
                                    <Fragment>
                                        <div className={classes.space}></div>
                                        <Grid item xs={12}  className={animation}>
                                            <ItemTransaction>
                                                <Grid container direction="row" style={{ height: 'inherit' }}>
                                                    <Grid item xs={12} md={2} style={{ border: '1px solid #E07E37', borderLeft: '3px solid #E07E37', height: isIpad ? '100px' : 'inherit' }} alignItems="center">
                                                        <Box className={clsx(classes.boxBodyTransaction)}>
                                                            <span style={{ fontWeight: 'bold', color: '#E07E37', fontSize: 13 }}>Transations</span>
                                                            <span style={{ color: '#E07E37', fontSize: 13 }}>Success</span>
                                                        </Box>
                                                    </Grid>
                                                    {isIpad && (<div style={{ width: 10, height: 10 }}></div>)}
                                                    <Grid item xs={10} style={{ padding: 10, display: 'flex', alignItems: 'center' }}>
                                                        <Grid container direction="column" justifyContent="center">
                                                        <Grid xs={12} style={{ marginBottom: 10 }}>
                                                            <Typography className={clsx(classes.fontText)}>
                                                                {_.get(element, 'hash')} {" "}
                                                            </Typography>
                                                        </Grid>
                                                        {isIpad && (<div style={{ width: 10, height: 10 }}></div>)}
                                                        <Grid xs={12} style={{ marginBottom: 10, display: 'flex', justifyContent: 'space-between' }}>
                                                            <Typography className={clsx(classes.fontText)}>
                                                                {_.get(element, 'blockHash')} {" "}
                                                            </Typography>
                                                            {!isIpad && (
                                                                <Typography className={clsx(classes.fontText)}>
                                                                    {moment(_.get(element, 'timestamp')).fromNow()} {" "}
                                                                </Typography>
                                                            )}
                                                        </Grid>
                                                        {isIpad && (<div style={{ width: 10, height: 10 }}></div>)}
                                                        <Grid container alignItems="center" direction="row">
                                                            <Typography className={clsx(classes.fontText)}>
                                                                {_.isEmpty(_.get(element, 'events')) ? (
                                                                    <Fragment>
                                                                        {_.get(element, 'from')}
                                                                    </Fragment>
                                                                ) : (
                                                                    <Fragment>
                                                                        {_.get(element, 'events[0].from')}
                                                                    </Fragment>
                                                                    
                                                                )}
                                                               
                                                            </Typography>
                                                            <ArrowRightAltIcon />
                                                            <Typography className={clsx(classes.fontText)}>
                                                                {_.isEmpty(_.get(element, 'events')) ? (
                                                                    <Fragment>
                                                                        {_.get(element, 'to')}
                                                                    </Fragment>
                                                                ) : (
                                                                    <Fragment>
                                                                        {_.get(element, 'events[0].to')}
                                                                    </Fragment>
                                                                    
                                                                )}
                                                            </Typography>
                                                        </Grid>
                                                        {isIpad && (<div style={{ width: 10, height: 10 }}></div>)}
                                                        {isIpad && (
                                                            <Typography className={clsx(classes.fontText)}>
                                                                {moment(_.get(element, 'timestamp')).fromNow()} {" "}
                                                            </Typography>
                                                        )}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </ItemTransaction>
                                        </Grid>
                                    </Fragment>
                                )
                            })}
                        </Fragment>

                    )}
               
                </Grid>
            </Box>
        </Grid>
    )
}

export default TransactionValidated

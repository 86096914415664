import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import OverViewTransaction from '../components/Transactions/Overview';
import { Grid, Box, Tab, Tabs, CircularProgress } from '@material-ui/core';
import { memuListTransfers } from '../helpers/index';
import PaginationComponent from '../components/PaginationComponent';
import TableComponent from '../components/TableComponent';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { transactionAPI } from '../service';
import _ from 'lodash';
import { PAGE_INDEX, PAGE_SIZE } from '../constant/slugs';
import queryString from 'qs';

const Results = () => {
  
    const { hash } = useParams(); 
    const location = useLocation();
    const [tabIndex, setTabIndex] = useState(0);
    const [balance, setBalance] = useState(0);
    const [pageIndex, setPageIndex] = useState(PAGE_INDEX);
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const [skip, setSkip] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    let { searchKey } = queryString.parse(location.search, { ignoreQueryPrefix: true });
    const [data, setData] = useState();
    const firstRender = useRef(true);
    const useStyles = makeStyles((theme) => ({
        container: {
            flexGrow: 1, 
            backgroundColor: '#fff', 
            padding: '24px 0', 
            borderRadius: 12, 
            width: '100%'
        }, 
        indicator: {
            backgroundColor: '#00837B !important'
        },
        tabs: {
            marginBottom: 32
        },
        titleTab: {
            color: '#748CAD', 
            textTransform: 'capitalize', 
            fontSize: 20,
            fontFamily: 'Montserrat'
        },
        wrapperLoading: {
            width: '100%', 
            justifyContent: 'center', 
            display: 'flex',
            margin: '12px 0'
        }
    }));

    const classes = useStyles();

    const handleChange = (e, newValue) => {
        setTabIndex(newValue);
    }

    const loadData = async () => {
        setLoading(true);
        if(!_.isEmpty(searchKey)) {
            Promise.all([transactionAPI.getBalanceAddress(searchKey), transactionAPI.getList(skip, pageSize, undefined, searchKey)]).then(data => {
                if(_.isArray(data) && data[1]) {
                    setData(data[1]);
                    setBalance(data[0]);
                }
                setLoading(false);
            })
            
        }
      
    }

    const searchData = () => {
        setLoadingTable(true);
        Promise.all([transactionAPI.getBalanceAddress(searchKey), transactionAPI.getList(skip, pageSize, undefined, searchKey)]).then(data => {
            if(_.isArray(data) && data[0] && data[1]) {
                setData(data[1]);
                setBalance(data[0]);
            }
            setLoadingTable(false);
        })
    
    }



    useEffect(() => {
        if(firstRender.current) {
            firstRender.current = false;
            return;
        }
        searchData();
    }, [searchKey, skip, pageSize]);

    useEffect(() => {
        loadData();
    }, []);

    

    if(loading) return <div className={classes.wrapperLoading}><CircularProgress style={{ margin: 'auto' }} disableShrink /></div>

    return (
        <Grid container direction="column" style={{ padding: '24px 24px' }}>
        <OverViewTransaction address={hash} balance={balance} data={data} />
        <Box className={clsx(classes.container)}>
            <Tabs
                value={tabIndex}
                onChange={handleChange}
                classes={{
                    indicator: classes.indicator
                }}
                className={clsx(classes.tabs)}
            >
                <Tab label={<span className={clsx(classes.titleTab)}>Transfers</span>} />
            </Tabs>
            {
                tabIndex === 0 && (
                    <Fragment>
                        {loadingTable ? (
                            <div className={clsx(classes.wrapperLoading)}><CircularProgress style={{ margin: 'auto' }} disableShrink /></div>
                        ) : (
                            <>
                                <TableComponent loading={loadingTable} hash={hash} data={_.get(data, 'transactions')} headMenu={memuListTransfers} />
                                <PaginationComponent 
                                    setPageSize={setPageSize}
                                    setPageIndex={(index) => {
                                        setPageIndex(index);
                                        setSkip(index * pageSize - pageSize)
                                    }}
                                    pageSizeOptions={[10, 20, 40, 80, 120]}
                                    pageSize={pageSize}
                                    pageIndex={pageIndex}
                                    total={_.get(data, 'totalCount')}  
                                    pagename="transactions"
                                />
                            </>
                           
                        )}
                 
                    </Fragment>
                )
            }   
            
        </Box>
    </Grid>
              
    )
}

export default Results

import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { transactionAPI } from '../../service/index';
import { useHistory } from 'react-router-dom';
import { makeStyles, CircularProgress } from '@material-ui/core';
import _ from 'lodash';

import { useMediaQuery } from 'react-responsive';
import TableComponent from '../TableComponent';
import { memuListTransfers } from '../../helpers/index';

import { ReactComponent as ArrowRight } from '../../assets/icons/arrow.svg';
import PaginationComponent from '../PaginationComponent';
import { PAGE_INDEX, PAGE_SIZE } from '../../constant/slugs';

const TransactionContainer = ({ searchText }) => {
    const [loadingTransaction, setLoadingTransaction] = useState(true);

    const [dataLst, setDataLst] = useState([]);

    const location = useLocation();

    const [animation, setAnimation] = useState("animate__animated animate__fadeInUp");
    const [loading, setLoading] = useState(false);

    const isIpad = useMediaQuery({ maxWidth: 768 });

    const [pageIndex, setPageIndex] = useState(PAGE_INDEX);
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const [skip, setSkip] = useState(0);

    const history = useHistory();

    const useStyles = makeStyles((theme) => ({
        buttonHead: {
            border: '1px solid #00837B',
            color: '#00837B',
            fontSize: 12,
            padding: '5px 20px',
            textTransform: 'capitalize',
            '&:hover': {
                color: '#fff',
                backgroundColor: '#00837B'
            }
        },
        fontText: {
            fontSize: 13,
            color: '#00837B',
            wordBreak: 'break-all'
        },
        boxContainer: {
            border: '1px solid lightgray',
            overflowY: 'scroll',
            borderRadius: 3,
            borderLeft: '4px solid #00837B',
            marginLeft: 0
        },
        space: {
            width: 10,
            height: 30
        },
        titleBox: {
            fontWeight: 'bold',
            color: '#15BBA6',
            fontSize: 13,
            margin: 10
        },
        bodyBox: {
            marginLeft: 10,
            marginRight: 10,
            fontSize: 13,
            color: '#a3a9b5',
            display: 'flex',
            alignItems: 'center'
        },
        menuTransations: {
            backgroundColor: '#d1ecf1',
            height: 50,
            borderRadius: 6,
            borderColor: '#00837B',
            padding: '0px 20px'
        },
        boxBodyTransaction: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
            backgroundColor: 'rgba(253,126,20,.1)'
        },
        tags: {
            borderRadius: 6,
            padding: '0px 6px',
            height: '25px'
        },
        titleContainer: {
            fontSize: 20,
            fontWeight: 500
        },
        wrapperLoading: {
            display: 'flex',
            justifyContent: 'center',
            margin: '24px 0'
        }
    }));

    const classes = useStyles();

    let oldTransaction = [];

    // const initData = async () => {
    //     if(location.pathname !== '/') {
    //         return;
    //     } else {
    //         setAnimation("");
    //         const data = await transactionAPI.getList(0, 15, undefined, searchText);
    
    //         if(_.get(data[0], 'number') !== _.get(oldTransaction[0], 'number')) {
    //             setAnimation("animate__animated animate__fadeInUp")
    //         }
    //         oldTransaction = data;
    
    //         setDataLst(data);
    //     }
      
    // }

    const firstRender = useRef(true);

    const initData = async () => {
        
        if(location.pathname !== '/') {
            return;
        }

        const data = await transactionAPI.getList(skip, pageSize, undefined, searchText);
        setDataLst(data) 
    }

    useEffect(() => {
        async function initDataFunc() {
            setLoading(true);
            await initData();
            setLoading(false);
        }

        initDataFunc();
      
        let interval = setInterval(() => initData(), 4000);
        return () => {
            clearInterval(interval)
        }

    }, [searchText, skip, pageSize]);


    useEffect(() => {
        async function loadFirstData() {
            setLoading(true);
            await initData();
            setLoading(false);
        }
        loadFirstData()
    }, []);

    return (
        <Fragment>
                {loading ? <div className={classes.wrapperLoading}><CircularProgress style={{ margin: 'auto' }} disableShrink /></div> : (
                    <>                   
                        <TableComponent loading={loading} data={_.get(dataLst, 'transactions')} icon={ArrowRight} headMenu={memuListTransfers} />
                        <PaginationComponent 
                            setPageSize={setPageSize}
                            setPageIndex={(index) => {
                                setPageIndex(index);
                                setSkip(index * pageSize - pageSize)
                            }}
                            pageSizeOptions={[10, 20, 40, 80, 120]}
                            pageSize={pageSize}
                            pageIndex={pageIndex}
                            total={_.get(dataLst, 'totalCount')}  
                            pagename="tractionsDetail"
                        />
                    </>
                        
                )}
            
        </Fragment>
    )
}

export default TransactionContainer

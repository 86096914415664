import React, { Fragment, useRef, useState } from 'react';
import { Button, ClickAwayListener, Grid, Grow, IconButton, MenuItem, MenuList, Paper, Popper, TextField, Typography } from '@material-ui/core';
import { ReactComponent as Logo } from '../assets/logo/logoEcoe.svg';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { ReactComponent as SearchIcon } from '../assets/icons/SearchIcon.svg';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ArrowDropDownIcon } from '../assets/icons/ArrowDropDown.svg';
import { useDebounce } from 'use-debounce';

import { useMediaQuery } from 'react-responsive';

import { ReactComponent as DehazeIcon } from '../assets/icons/Dehaze.svg';
import { ReactComponent as RemoveIcon } from '../assets/icons/RemoveInput.svg';
import _ from 'lodash';

const Header = () => {
    const [searchText, setSearchText] = useState(undefined);
    const [value] = useDebounce(searchText, 1000);

    const [openSearch, setOpenSearch] = useState(false);

    const isIpad = useMediaQuery({ minWidth: 500 });
    const isMobile = useMediaQuery({ maxWidth: 499 })

    const useStyles = makeStyles((theme) => ({
        menuContainer: {
            backgroundColor: '#00837B', 
            height: 57,  
            padding: '0px 24px',
        },
        noBorder: {
            border: "none",
        },
        input: {
            '&::placeholder': {
                color: '#fff',
                fontSize: 14,
            }
        },
        inputContainer: {
            color: '#fff'
        },
        buttonDropdown: {
            marginRight: 100, 
            color: '#fff', 
            display: 'flex', 
            alignItems: 'center'
        }
    }));

    const history = useHistory();

    const [open, setOpen] = useState(false);

    const anchorRef = useRef(null);

    const classes = useStyles();

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
          event.preventDefault();
          setOpen(false);
        }
    }

    const searchData = (e) => {
        if(e.key === 'Enter') {
            setOpenSearch(false)
            history.push(`/results?searchKey=${searchText}`)
        } 
    }

    return (
        <Grid container direction="column">
            <Grid container className={clsx(classes.menuContainer)} alignItems="center" justifyContent="space-between">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    {!openSearch && <Logo style={{ cursor: 'pointer' }} width={"120px"} onClick={() => history.push('/')} />}
                    {/* <Button
                        ref={anchorRef}
                        className={clsx(classes.buttonDropdown)}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                    >
                       <div style={{ display: 'flex', alignItems: 'center' }}>{!isIpad ? (<Fragment><Typography style={{ textTransform: 'capitalize' }}>Transactions</Typography><ArrowDropDownIcon fill='#fff' /></Fragment>) : <DehazeIcon fill="#fff" />} </div> 
                    </Button>
                    <Popper open={open} anchorEl={anchorRef.current} style={{ zIndex: 999 }} role={undefined} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom', width: 140 }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        {isIpad && (
                                            <MenuItem onClick={() => history.push('/')}>Dashboard</MenuItem>
                                        )}
                                        <MenuItem onClick={() => history.push('/transactions/validated')}>Validated</MenuItem>
                                        <MenuItem onClick={() => history.push('/transactions/pending')}>Pending</MenuItem>
                                    
                                    </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper> */}
                    {(!openSearch && isMobile) ? <SearchIcon onClick={() => setOpenSearch(true)} fill='#fff' style={{ cursor: 'pointer' }} /> : (
                        <TextField 
                            variant="outlined"
                            onChange={(e) => setSearchText(e.target.value)}
                            value={searchText}
                            size="small" 
                            onKeyPress={searchData}
                            placeholder="Tìm kiếm..."
                            style={{ background: '#009087', borderRadius: 4, width: isIpad ? 280 : "100%", color: '#fff', margin: 0 }} 
                            InputProps={{
                                classes:{notchedOutline:classes.noBorder, input: classes.input},
                                className:classes.inputContainer,
                                startAdornment: <SearchIcon fill='#fff' style={{ cursor: 'pointer' }} />,
                                endAdornment: !_.isEmpty(searchText) && <RemoveIcon onClick={() => setSearchText("")} />
                            }}
                        />
                    )}
                   
                </div>
            </Grid>
        </Grid>
    )
}

export default Header
